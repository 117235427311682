import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import featureImage from "../../static/images/vokabox_teacher_landing.png"
import thumbnailEvent2 from "../../static/images/feature-event2.png"
import thumbnailNews from "../../static/images/feature-news1.png"
import thumbnailTeams from "../../static/images/feature-team1.png"
import thumbnailStaff from "../../static/images/feature-user1.png"

const IndexPage = () => (
  <Layout ishome={true}>
    <SEO title="We make learning german fun and easy" />

    <div className={"page-header home"}>
      <div className={"container"}>
        <h1>
          <span className={"Highlight--highlight_wrapper"}>
            <span className={"underline-sprkr"}>vokabox</span>
            <span className={"Highlight--highlight_color"}></span>
          </span>
          so many ways to learn German
        </h1>
        <p>
          vokabox is the ultimate vocab-box to go: each word comes as a unique
          “card”, with example dialogue, grammar hints, pseudo translation into
          English, audio samples for both the word and the dialogue -- spoken by
          various native speakers -- recording option and illustration.
        </p>
        <img id="feature-banner-img" alt={"Lucky vokabox card"} src={featureImage} />
      </div>
    </div>

    <div className={"container"}>
      <div className={"features"}>
        <div className={"feature__item"}>
          <div className={"row"}>
            <div className={"col-6 first"}>
              <div className={"thumbnail"}>
                <img alt={"Event"} src={thumbnailStaff} />
              </div>
            </div>

            <div className={"col-6"}>
              <div className={"feature__content"}>
                <h2>The Classic Skills</h2>
                <p>
                  <ul>
                    <li>
                      <span className={"Highlight--highlight_wrapper"}>
                        <span className={"underline-sprkr text-uppercase"}>
                          Reading
                        </span>
                        <span
                          className={"Highlight--highlight-thin_color"}
                        ></span>
                      </span>
                      : word + dialogue
                    </li>
                    <li>
                      <span className={"Highlight--highlight_wrapper"}>
                        <span className={"underline-sprkr text-uppercase"}>
                          Listening
                        </span>
                        <span
                          className={"Highlight--highlight-thin_color"}
                        ></span>
                      </span>
                      : the dialogue spoken by various native speakers from
                      different corners of D(?)
                    </li>
                    <li>
                      <span className={"Highlight--highlight_wrapper"}>
                        <span className={"underline-sprkr text-uppercase"}>
                          Speaking
                        </span>
                        <span
                          className={"Highlight--highlight-thin_color"}
                        ></span>
                      </span>
                      : record yourself and compare your pronunciation with the
                      audio example
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className={"feature__item"}>
          <div className={"row"}>
            <div className={"col-6"}>
              <div className={"feature__content"}>
                <h2>Skills Are Parallely Activated</h2>
                <p>Seeing + Hearing enhances the chance to remember</p>
              </div>
            </div>

            <div className={"col-6 first"}>
              <div className={"thumbnail"}>
                <img alt={"Board"} src={thumbnailTeams} />
              </div>
            </div>
          </div>
        </div>

        <div className={"feature__item"}>
          <div className={"row"}>
            <div className={"col-6 first"}>
              <div className={"thumbnail"}>
                <img alt={"Event"} src={thumbnailNews} />
              </div>
            </div>

            <div className={"col-6"}>
              <div className={"feature__content"}>
                <h2>Visual Aids</h2>
                <p>
                  Colour coding for nouns or illustrations (not pictures!) also
                  help to better connect with a word
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className={"feature__item"}>
          <div className={"row"}>
            <div className={"col-6"}>
              <div className={"feature__content"}>
                <h2>Dialogues</h2>
                <p>Create context and by that enhance the word’s relevance</p>
              </div>
            </div>

            <div className={"col-6 first"}>
              <div className={"thumbnail"}>
                <img alt={"Board"} src={thumbnailEvent2} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className={"call-to-action"}>
      <div className={"container"}>
        <div className={"call-to-action__content"}>
          <h2>Sign up For Free</h2>
          <p>Sign up and start learning german with ease and pleasure.</p>
        </div>

        <div className={"button"}>
          <a href="https://app.vokabox.com" target={"_blank"} rel="noreferrer">
            Goto app
          </a>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
